import React, { useEffect, useState } from 'react';
import TimelineEvent from './timelineEvent';

const TimelineYear = (DATA) => {

    useEffect(() => {
        // sort timeline events on CSS Grid via JS.
        // map (event){
//            let element = document get element byclasname('.event-width-id__2019-01')
//             element.gridColumn = 2
        // } 


        // need to sort timeline events basd on month.
        // need to create gaps in opposing side for each event on existing side.
            // get height of all events
        let startEvents = [...DATA.events].filter(data => data.startEvent);
        let endEvents = [...DATA.events].filter(data => !data.startEvent);

        // console.log('start events: ', startEvents);
        // console.log('end events: ', endEvents);

                // console.log(eventElementsForYear, typeof(eventElementsForYear))
        // console.log(eventElementsForYear[0].dataset.startEvent)

        let eventElementsForYear = document.getElementsByClassName(`timeline-event__${DATA.year}`);

        let startEventElements = [...eventElementsForYear].filter(evnt => evnt.dataset.startEvent === "true");
        let endEventElements = [...eventElementsForYear].filter(evnt => evnt.dataset.startEvent === "false");

        let startEventsGrid = document.getElementsByClassName(`start-events__${DATA.year}`)[0];
        let startEventsLength = [...startEventElements].length;
        startEventsGrid.style.gridTemplateRows = `repeat(${startEventsLength}, auto)`;
        // console.log(startEventsGrid, startEventsLength);

        let endEventsGrid = document.getElementsByClassName(`end-events__${DATA.year}`)[0];
        let endEventsLength = [...endEventElements].length;
        endEventsGrid.style.gridTemplateRows = `repeat(${endEventsLength}, auto)`;
        // console.log(endEventsGrid, endEventsLength);

       
        
        let startEventElementHeights = startEventElements.map(evnt => evnt.clientHeight);
        // let sortedMonths = [...startEvents].map(evnt => evnt.month).sort((a, b) =>  a - b)
        // console.log(startEventElementHeights)
        let endEventElementHeights = endEventElements.map(evnt => evnt.clientHeight);
        // console.log(endEventElementHeights)

        // add opposing heights as gaps within corresponding grid.

        // let startEventMonths = [...startEvents].map(evnt => {return {month: evnt.month, index: startEvents.indexOf(evnt)}});
        // let endEventMonths = [...endEvents].map(evnt => {return {month: evnt.month, index: endEvents.indexOf(evnt)}});
        let startEventMonths = [...startEvents].map(evnt => evnt.month);
        let endEventMonths = [...endEvents].map(evnt => evnt.month);
        // console.log(startEventMonths, endEventMonths);

        let goesAfter = []
        for(let i = 0; i < Math.max(startEventMonths.length, endEventMonths.length); i++) {
            let winner = Math.max(startEventMonths[i], endEventMonths[i]);
            goesAfter.push(winner)

        }

        
        for(let k = 0; k < Math.max(startEventMonths.length, endEventMonths.length); k++) {
            if(Math.min(startEventMonths.length, endEventMonths.length) == 0) { break; }
            
            if(startEvents[k] != undefined && startEvents[k].month == goesAfter[k]) {
                // this start events height goes after the corresponding end event element
                if(endEvents[k + 1] != undefined && endEvents[k + 1].month < startEvents[k].month) {
                    endEventElements[k + 1].style.marginBottom += `${startEventElementHeights[k]}px`
                    startEventElements[k].style.marginTop += `${endEventElementHeights[k] + endEventElementHeights[k + 1]}px`
                } else { 
                    endEventElements[k].style.marginBottom += `${startEventElementHeights[k]}px`
                    // console.log(endEventElements, startEventElements)
    
                    // this start event needs to have its correspoonding height above it
                    startEventElements[k].style.marginTop += `${endEventElementHeights[k]}px`
                }
      
            } else {
                if(endEventElements.length <= k) { break }
                // if(endEventElements[k] == undefined && startEventElements[k] == undefined) { break }
                // this end events height goes before the corresponding start event element
                endEventElements[k].style.marginTop += `${startEventElementHeights[k]}px` // - endEventElementHeights[k]
                // console.log(endEventElements, startEventElements)
                if(startEventElements.length <= k) { break }
                // this 
                // console.log(startEventElements[k], k)
                startEventElements[k].style.marginBottom += `${endEventElementHeights[k]}px`
            }
        }
        // 6  4
        // 6 is > 4 - so take 6 height and place before 4
        // 8  12
        // 8 is < 12 - so take 8 height and place before 12

        // 4  6
        // 4 is < 6 - so take 4 height and place after 4
        // 12 8
        // 12 is > 8 - so take 12 height and place after 8        
    })

    return (
        <div className={`timeline-year`}>
            <div className='timeline-year__title'>{DATA.year}</div>
            <div className='timeline-year__line'/>
            <div className={`timeline-year__start-events start-events__${DATA.year}`}>
                {
                    DATA.events.filter(eventData => eventData.startEvent).map(eventData => {
                        return <TimelineEvent key={eventData._id} {...eventData} year={DATA.year} />
                    })
                }
            </div>
            <div className={`timeline-year__end-events end-events__${DATA.year}`}>
                {
                    DATA.events.filter(eventData => !eventData.startEvent).map(eventData => {
                        return <TimelineEvent key={eventData._id} {...eventData} year={DATA.year} />
                    })
                }
            </div>
        </div>
    )
}

export default TimelineYear;
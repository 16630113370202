import React, { Component } from 'react'

const socialProfiles = [
   {
      icon: 'youtube',
      url: 'https://www.youtube.com/maxcodes'
   },
   // {
   //    icon: 'twitter',
   //    url: 'https://www.twitter.com/maxcodes1'
   // },
   {
      icon: 'linkedin',
      url: 'https://www.linkedin.com/in/maxwellnelson'
   },
   {
      icon: 'instagram',
      url: 'https://www.instagram.com/maxcodes'
   },
   // {
   //    icon: 'spotify',
   //    url: 'https://www.spotify.com/maxcodes'
   // },
   {
      icon: 'medium',
      url: 'https://medium.com/@max.codes'
   }
]

class SocialProfiles extends Component {
   render() {
       const { className } = this.props
       return (
            <div className={`${className} social-profiles`}>
               {
                  socialProfiles.map(({url, icon}, index) => {
                     return <img key={index} target="_blank" onClick={() => this.open(url)}src={`/assets/${icon}.png`}/>
                  })
               }
            </div>
       )
   }

   open = (url) => {
      window.open(url);
    }
}

export default SocialProfiles;
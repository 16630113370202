import React from 'react';
import ReactDOM from 'react-dom';

import { Router, Route, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers';

import history from './history';

import Home from './components/home/home';
import Layout from './components/root/layout';

import reduxThunk from 'redux-thunk';

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(compose((window.devToolsExtension ? window.devToolsExtension() : f=>f)(createStore)));

// import 'bootstrap/dist/css/bootstrap.css';
import './style/main.scss';
import IOSApps from './components/projects/IOSApps';
import WebApps from './components/projects/webApps';
import Projects from './components/projects/projects';
import About from './components/about/about';
import Contact from './components/contact/contact';
import Resume from './components/resume/resume';
import BreakpointProvider from './components/breakpoint/breakpointProvider';

const queries = {
  xs: '(max-width: 320px)',
  md: '(max-width: 720px)',
  lg: '(max-width: 1024px)',
  xl: '(max-width: 1280px)',
  mobile: '(max-width: 1200px)'
}


function main() {
  ReactDOM.render(
    <Provider store={createStoreWithMiddleware(reducers)}>
      <Router history={history}>
        <BreakpointProvider queries={queries}>
          <Layout>
            <Switch>
              <Route path='/' exact component={Home}/>
              <Route path='/projects/:type' exact component={Projects}/>
              {/* <Route path='/web' exact component={WebApps}/> */}
              <Route path='/about' exact component={About}/>
              <Route path='/app architecture' exact component={About}/>
              <Route path='/contact' exact component={Contact}/>
              <Route path='/resume' exact component={Resume}/>
            </Switch>
          </Layout>
        </BreakpointProvider>
      </Router>
    </Provider>
    , document.querySelector('.app-wrapper'));
}

document.addEventListener('DOMContentLoaded', main);

import { SET_UDEMY_COURSES } from '../actions/types';

const INITIAL_STATE = {
    udemyCourses: []
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case SET_UDEMY_COURSES:
            return {
                ...state,
                udemyCourses: action.payload
            }
        default: return INITIAL_STATE
    }
}
import React, { Component } from 'react'

let logos = [
    '/assets/technologies/js.png',
    '/assets/technologies/react.png',
    '/assets/technologies/redux.png',
    '/assets/technologies/node.png',
    '/assets/technologies/express.png',
    '/assets/technologies/docker.png',
]

const TechLogos = ({className}) => {
    return (
        <div className={className}>
            {
                logos.map((logo, index) => {
                    return <img key={index} src={logo}/>
                })
            }
        </div>
    )
}

class Technologies extends Component {
   render() {
       const { className } = this.props
       return (
            <div className={`${className} technologies`}>
               <div className='technologies__title'>The goal isn't just to finish a project. The goal is to finish it correctly.</div>
               <div className='technologies__description'>Here is a collection of languages, frameworks, and libraries I use to build projects intelligently.</div>
               <TechLogos className='technologies__logos'/>
            </div>
       )
   }
}

export default Technologies;
import React, { useRef, useEffect } from 'react'
import ProjectsHeader from './projectsHeader';
import IOSProject from './IOSProject';

import {
   useParams
 } from "react-router-dom";


let projectData = [
   {
      _id: 0,
      title: "PocketPapers",
      subtitle: "wallpapers, in your pocket.",
      iconUrl: "/assets/iosapps/pp/logo.jpg",
      images: [
         "/assets/iosapps/pp/1.jpg",
         "/assets/iosapps/pp/2.jpg",
         "/assets/iosapps/pp/3.jpg",
         "/assets/iosapps/pp/5.jpg",
         "/assets/iosapps/pp/4.jpg"
      ],
      appStoreUrl: "https://apps.apple.com/us/app/pocketpapers/id1320376517?ls=1"
   },
   {
      _id: 2,
      title: "GPYay!",
      subtitle: "College GPA Calculator",
      iconUrl: "/assets/iosapps/gpyay/logo.jpg",
      images: [
         "/assets/iosapps/gpyay/1.jpg",
         "/assets/iosapps/gpyay/2.jpg",
         "/assets/iosapps/gpyay/3.jpg",
         "/assets/iosapps/gpyay/4.jpg",
      ],
      appStoreUrl: "https://apps.apple.com/us/app/gpyay/id1112838673?ls=1"
   },
   {
      _id: 3,
      title: "Decipher",
      subtitle: "QR Code Scanner",
      iconUrl: "/assets/iosapps/decipher/logo.jpg",
      images: [
         "/assets/iosapps/decipher/1.png",
         "/assets/iosapps/decipher/2.png",
         "/assets/iosapps/decipher/3.png",
         "/assets/iosapps/decipher/4.png",
         "/assets/iosapps/decipher/5.png",
      ],
      appStoreUrl: "https://apps.apple.com/us/app/decipher-qr-code-scanner/id1299774528?ls=1"
   },
   {
      _id: 4,
      title: "Color On Me",
      subtitle: "Photo Editing & Filters",
      iconUrl: "/assets/iosapps/com/logo.jpg",
      images: [
         "/assets/iosapps/com/1.png",
         "/assets/iosapps/com/2.png",
         "/assets/iosapps/com/3.png",
         "/assets/iosapps/com/4.png",
         "/assets/iosapps/com/5.png",
      ],
      appStoreUrl: "https://apps.apple.com/us/app/color-on-me/id1255071108?ls=1"
   },
   {
      _id: 1,
      title: "College Course Average",
      subtitle: "Calculate weighted grade groups.",
      iconUrl: "/assets/iosapps/cga/logo.jpg",
      images: [
         "/assets/iosapps/cga/1.jpg",
         "/assets/iosapps/cga/2.jpg",
         "/assets/iosapps/cga/3.jpg",
         "/assets/iosapps/cga/4.jpg",
      ],
      appStoreUrl: "https://apps.apple.com/us/app/college-course-average/id1178814697?ls=1"
   },
]


function IOSApps(props) {
   const { className } = props;
   let { type } = useParams();
   const ref = useRef();
   useEffect(() => {
      const body = document.getElementsByTagName("BODY")[0];
      body.scrollIntoView({
          behavior: 'auto'
      }, 500)
  }, []);
   return (
      <div ref={ref} className={`${className} ios-apps`}>
         <ProjectsHeader className='ios-apps__project-header' title='iOS Apps' subtitle="apps I've built"/>
         <h1>current filter: {type}</h1>
         <div className='ios-apps__projects'>
            {
               projectData.map(project => {
                  return <IOSProject key={project._id} {...project}/> 
               })
            }
         </div>
      </div>
   )
}

export default IOSApps;
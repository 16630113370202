import React, { Component, useEffect, useState, useCallback } from 'react'
import history from '../../history';
import {useSpring, animated} from 'react-spring';
import { shallowEqual, useSelector } from 'react-redux';

let blockData = [
    {
        url: '/projects/web',
        _id: 1,
        icon: "assets/safari.png",
        title: "Web Apps",
        description: "Any front end web app must be created in a manner that allows itself to scale and this requires precision from the moment you create the root folder. It all must be structurally sound. With an innate eye for spatial design and love for organization, I build web apps that can be understood by other developers, and survive new features down the road."
    },
    {
        url: '/projects/repo',
        _id: 2,
        icon: "assets/github.png",
        title: "GitHub Repos",
        description: "When building applications I often create new features that can be used in multiple apps. So I package the solution up and put it in a GitHub repo (npm soon.) As I do this I track each step with detailed commits, branches, and PR's to give me continuous Git practice and easy points of reference to look back on."
    },
    {
        url: '/projects/ios',
        _id: 0,
        icon: "assets/app-store.png",
        title: "iOS Apps",
        description: "As a kid, I was fascinated by the iPod Touch & Apple App Store. Then I realized I could make it my career. I've created six apps for the app store that include technologies like QuartzCore for photo editing, CoreData for local data persistence, and custom solutions like masonry layouts."
    },
    //,
    // {
    //     url: 'https://www.maxcodes.io',
    //     _id: 2,
    //     icon: "assets/video.png",
    //     title: "maxcodes.io",
    //     description: "I build in-depth iOS software development courses that show students how to build iOS apps, integrate powerful frameworks and features into their own projects, and upgrade their skill. I have taught over 30,000 iOS developers. I love it."
    // },
]

const Block = ({_id, icon, title, description, url}) => {

    return (
        <div className='block'>
            <img className='block__icon' src={icon}/>
            <div className='block__title'>{title}</div>
            <div className='block__description'>{description}</div>
            {
                // _id == 2 ? 
                // <a className='block__button' 
                //     target='_blank'
                //     href={url}
                // >
                //     More Info
                // </a>
                // :
                <a className='block__button'
                    onClick={() => history.push(url)}
                >
                    More Info
                </a>
            }
        </div>
    )
}



const Blocks = (props) => {

    // let [fadeInProps, set, stop] = 
    // useSpring(() => {
    //     transform: `translate(-75px, 0px)`
    // })

    // useSpring(
    //     {
    //         from: { transform: `translate(0px, 0px)`, opacity: 0 },
    //         to: { transform: 'translate(0px, 0px)', opacity: 1 }
    //     }
    // )

    // const {
    //     scrollY,
    //     scrollX,
    //     scrollDirection
    // } = useSelector(state => state.scrollValues, shallowEqual)

    // `translate(-${scrollY/75}px, 0px)`

   return (
        <div className={`${props.className} blocks`}>
                <div className='blocks__img-cover'/>
                <img className='blocks__img' src='/assets/blocks-feature.jpg'/>
                <animated.div className='blocks__title'>Portfolio Projects</animated.div>
                <div className='blocks__description'>Front end development is my core strength, however, I've spent some time building iOS apps. This breaks my projects into three categories.</div>
                <div className={`${props.className} blocks__blocks`}>
                {
                    blockData.map((block, index) => {
                        return <Block key={index} {...block}/>
                    })
                }
                </div>
        </div>
       )
}

export default Blocks;
import React, { Component } from 'react'

import SocialProfiles from '../clusters/socialProfiles';
import MCButton from '../clusters/MCButton';

import Typewriter from '../typewriter';

// const typeWriterElements = '[ "build iOS apps.", "communicate.", "love design.", "write unit tests.", "am a team player.", "like running.", "read many books.", "snowboard." ]'

const typeWriterElements = '["build web apps.", "take a test driven approach.", "love JavaScript.", "utilize agile methodologies.", "enjoy UI/UX.", "am a team player.", "read books.", "snowboard.", "like cars."]'

class HomeHeader extends Component {
   render() {
       const { className } = this.props
       return (
            <div className={`${className} home-header`}>
                <div className='home-header__particles' id="particles-js"></div>
                <img className='home-header__phone-feature' src='assets/iphonelanding003.png'/>
                <div className='home-header__title'>Maxnelson</div>
                {/* <div className='home-header__typewriter'>I build apps.</div> */}
                <Typewriter className='home-header__typewriter' elements={typeWriterElements}/>
                <MCButton onClick={() => this.handleViewProjects()} className='home-header__button' text='View Projects'/>
                <SocialProfiles className='home-header__social-profiles'/>
                <div className='home-header__subtitle'>connect with me anywhere.</div>
                <div className='home-header__background'></div>
            </div>
       )
   }

   handleViewProjects = () => {
        this.props.history.push('/projects/all')
   }
}

export default HomeHeader;
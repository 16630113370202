import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux';
import ProjectsHeader from './projectsHeader';

import Project from './project';
import ProjectsFilter from './projectsFilter';

let Projects = (props) => {

   const { className } = props;

   const {
      selectedCategory,
      projects
   } = useSelector(state => state.projects, shallowEqual);

    useEffect(() => {
        const body = document.getElementsByTagName("BODY")[0];
        body.scrollIntoView({
            behavior: 'auto'
        }, 500)
    }, []);

   return (
      <div className={`${className} projects`}>
         <ProjectsHeader className='projects__projects-header' title="Projects" subtitle="A collection of projects tagged with technologies used."/>
         <ProjectsFilter className='projects__projects-filter'/>
         <div className='projects__projects'>
            {
               projects.map(project => {
                  return project.type == selectedCategory || 'all' == selectedCategory ? <Project key={project._id} {...project}/> : ''
               })
            }
         </div>
      </div>
   )
}


export default Projects;
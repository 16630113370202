import React, { Component } from 'react'
import axios from 'axios';
import ProjectsHeader from '../projects/projectsHeader';
class Contact extends Component {

    componentDidMount() {
        let form = document.getElementById("myForm")

        form.addEventListener("submit", function(e){
            e.preventDefault();

            let action = 'https://getform.io/f/8cd382d8-642b-43df-b60b-8cecb11836af';
            let formData = new FormData(form)

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            axios.post(action, formData, config)
                .then(res => {
                    let contactPage = document.getElementById("contact-page");
                    contactPage.classList.add('contact-message-sent');
                })
                .catch(err => {
                    // alert('hmm there was an issue sending your message. Try emailing it to me instead at maxnelson997@gmail.com')
                    console.log(err)
                })
        });
    }
    
   render() {
       const { className } = this.props
       return (
            <div className={`${className} contact`} id='contact-page'>
                 <ProjectsHeader className='contact__header' title='Who Is Max?' subtitle="Need a JavaScript dev? Hit me up."/>
                <div className='contact__card'>
                    <div className='contact__card__form-container'>
                        <form className='form' id="myForm" action="https://getform.io/f/8cd382d8-642b-43df-b60b-8cecb11836af" method="POST">
                            <div className='form__name form-group'>
                                <label className='form-group__title'>name</label>
                                <input type="text" name="name"/>
                            </div>
                            <div className='form__email form-group'>
                                <label className='form-group__title'>email</label>
                                <input type="email" name="email"/>
                            </div>
                            <div className='form__message form-group'>
                                <label className='form-group__title'>message</label>
                                <textarea type="text" name="message"/>
                            </div>
                            <button className='form__send-button mcbutton' type="submit">Send</button>
                            
                        </form>
                    </div>
                    <div className='contact__card__sent-message'>
                        Your message was sent! I'll be with you shortly via email.
                    </div>
                </div>
              
            </div>
       )
   }
}

export default Contact;
import React, { Component } from 'react'
import MCButton from '../clusters/MCButton';

class IOSProject extends Component {
   render() {
       const { className, title, subtitle, images, appStoreUrl, iconUrl} = this.props
       return (
            <div className={`${className} ios-project`}>
                <div className='ios-project__images'>
                    {images.map((image, index) => {
                        return <img src={image} key={index}/>
                    })}
                </div>
                <div className='ios-project__card'>
                    <img className='ios-project__card__icon' src={iconUrl}/>,
                    <div className='ios-project__card__line'/>
                    <div className='ios-project__card__subtitle'>{subtitle}</div>
                    <div className='ios-project__card__title'>{title}</div>
                    <MCButton onClick={() => this.handleViewProjects(appStoreUrl)} className='ios-project__card__button' text='View On App Store'/>
                </div>
            </div>
       )
   }

   handleViewProjects = (appStoreUrl) => {
     window.open(appStoreUrl);
   }
}

export default IOSProject;
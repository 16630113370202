import React, { Component } from 'react';
import Links from '../clusters/links';
import { Link } from 'react-router-dom';
import MCButton from '../clusters/MCButton';

import history from '../../history';

export default class Nav extends Component {

  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     active: false
  //   }
  // }

  // showMenu = () => {
  //   this.setState({ active: !this.state.active })
  //   let elements = document.getElementsByClassName("nav");
  //   let nav = elements[0]
  //   if(this.state.active) {
  //     nav.classList.add(["showem"])
  //   } else {
  //     nav.classList.remove(["showem"])
  //   }
  // }
  
    render() {
      return (
        <div className={`${this.props.className} nav`}>
            {/* <a onClick={() => this.showMenu()} className='nav__menu'>menu</a> */}
            {/* svg shows up with no font on mobile */}
          <Link className='nav__logo' to='/'><img src='/assets/logo.png'/></Link>
          <Links className='nav__links'/>
          <MCButton className='nav__button' onClick={() => this.handleConnect()} text='Contact'/>
        </div>
      );
    }

    handleConnect = () => {
      history.push('/contact');
    }
  }
  


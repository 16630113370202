import React, { Component } from 'react'

const featurePhones = [
    {
        _id: 0,
        imageUrl: "/assets/iosapps/pp/3.jpg"
    },
    {
        _id: 1,
        imageUrl: "/assets/iosapps/gpyay/1.jpg"
    },
    {
        _id: 2,
        imageUrl: "/assets/iosapps/decipher/1.png"
    },
    {
        _id: 3,
        imageUrl: "/assets/iosapps/com/1.png"
    },
    {
        _id: 4,
        imageUrl: "/assets/iosapps/cga/3.jpg"
    },
    {
        _id: 5,
        imageUrl: "/assets/iosapps/pp/4.jpg"
    },
    {
        _id: 6,
        imageUrl: "/assets/iosapps/pp/5.jpg"
    },
]

class Phones extends Component {
   render() {
       const { className } = this.props
       return (
            <div className={`${className} phones`}>
                {/* <div className='phones__wrapper'> */}
                    <div className='phones__scroller'>
                    {
                        featurePhones.map(phone => {
                            return <img key={phone._id} src={phone.imageUrl}/>
                        })
                    }
                    </div>
                {/* </div> */}
            </div>
       )
   }
}

export default Phones;
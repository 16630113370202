import React, { Component } from 'react';
import Links from '../clusters/links';
import SocialProfiles from '../clusters/socialProfiles';
import MCButton from '../clusters/MCButton';

import history from '../../history';

export default class Footer extends Component {
    render() {
      return (
        <div className={`${this.props.className} footer`}>
          {/* <div className='footer__title'>Let's Build.</div> */}
          <div className='footer__subtitle'>My next career move is to commit myself long-term to the next company I work for.</div>
          <MCButton className='footer__button' onClick={() => this.handleConnect()} text='Contact'/>
          <div className='footer__line'/>
          <Links className='footer__links'/>
          <SocialProfiles className='footer__social-profiles'/>
        </div>
      );
    }

    handleConnect = () => {
      history.push('/contact');
    }
  }


import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class Links extends Component {

 
   render() {
       const { className } = this.props
       return (
            <div className={`${className} links`}>
               {/* <Link to='/ios'>ios apps</Link>
               <Link to='/web'>web apps</Link> */}
               <Link to='/about'>about max</Link>
               <Link to='/projects/all'>projects</Link>
               {/* <Link to='/contact'>contact max</Link> */}
               {/* <Link to='/about'>app architecture</Link> */}
               <a target='_blank' href="https://drive.google.com/file/d/14YQXLXOirHoAVjP3vGGLxpLUf4SS4Lma/view?usp=sharing">view resume</a>
               {/* <a target='_blank' href="https://www.maxcodes.io">maxcodes.io</a> */}
               
               {/* <Link to='/resume'>view resume</Link> */}
            </div>
       )
   }


}

export default Links;
import React, { useRef, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SET_PROJECT_CATEGORY } from '../../actions/types';

let ProjectsFilter = (props) => {
    let { type } = useParams();
    const ref = useRef();
    const dispatch = useDispatch(); 

    const {
        selectedCategory,
        projectsCategories,
    } = useSelector(state => state.projects, shallowEqual)

    useEffect(() => {
        if(selectedCategory != type) { selectFilter(type) }
    }, [type])

    const selectFilter = (category) => { dispatch({ type: SET_PROJECT_CATEGORY, payload: category }) }
    
    return (
        <div ref={ref} className={`${props.className} projects-filter`}>
            <div className='projects-filter__title'>Filter by project type</div>
            <div className='projects-filter__tags'>
                {
                    projectsCategories.map(tag => {
                        return <a onClick={() => selectFilter(tag.category)} key={tag._id} className={`filter-tag ${tag.selected ? 'filter-tag--selected' : ''}`}>{tag.title}</a>
                    })
                }
            </div>
        </div>
    )
}

export default ProjectsFilter;
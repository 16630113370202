import React, { Component, useCallback } from 'react';
import Nav from './nav';
import Footer from './footer';
import { useScroll } from './useScroll';
import { SET_SCROLL } from '../../actions/types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';


const Layout = ({children}) => {
    // const onScroll = useCallback(e => { console.log(e)})
    const dispatch = useDispatch(); 
    const {
      scrollY,
      scrollX,
      scrollDirection
    } = useScroll();

    const setScrollValues = (values) => { dispatch({ type: SET_SCROLL, payload: values }) }

    function storeScrollValues(){
      setScrollValues(
        {
          scrollY,
          scrollX,
          scrollDirection
        }
      );
    }
    
    return (
      <div className='layout' onScroll={storeScrollValues()}>
        <Nav className='layout__nav' />
        {children}
        <Footer className='layout__footer'/>
      </div>
    );
}

export default Layout;
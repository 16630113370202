import React, { Component } from 'react'
import ProjectsHeader from './projectsHeader';
import WebProject from './webProject';

let projectData = [
   {
      _id: 0,
      title: "maxcodes.io",
      subtitle: "Online learning. For iOS Developers. I built this with the MERN stack.",
      images: [
         "/assets/maxcodessiteshot.png",
      ],
      url: "https://www.maxcodes.io",
      available: true
   },
   {
      _id: 1,
      title: "apply.bottega.tech",
      subtitle: "Enrollment Web Application with various service integrations such as Calendly, Tuition Teller for payment processing, etc... I built this with the MERN stack.",
      images: [
         "/assets/applyfeature.png",
      ],
      url: "https://apply.bottega.tech",
      available: true
   },
   {
      _id: 2,
      title: "lyfe chrome extension",
      subtitle: "A chrome extension I built that shows you how many months you've completed in your life.",
      images: [
         "/assets/lifecompleted.png",
      ],
      url: "https://www.github.com/maxnelson997",
      available: false
   },
 ]
 

class WebApps extends Component {
   render() {
       const { className } = this.props
       return (
            <div className={`${className} web-apps`}>
                <ProjectsHeader className='web-apps__project-header' title='Web Apps' subtitle="websites I've built"/>
                <div className='web-apps__projects'>
                  {
                     projectData.map(project => {
                        return <WebProject key={project._id} {...project}/> 
                     })
                  }
               </div>
            </div>
       )
   }
}

export default WebApps;
import React from 'react';


const repos = [
    {
        _id: 0,
        title: 'Koa-Typescript',
        description: 'A koa typescript app. Redis for data, supported by Docker.',
        url: 'https://github.com/Maxnelson997/koa-typescript',
        logos: ['docker.png', 'node.png', 'koa.png']
    },
    {
        _id: 1,
        title: 'WebpackBabelReact-InDepth',
        description: 'A fully fleshed out webpack setup for react applications with SCSS support.',
        url: 'https://github.com/Maxnelson997/WebpackBabelReact-InDepth',
        logos: ['webpack.png', 'js.png', 'react.png']
    },
    {
        _id: 2,
        title: 'UICollectionViewFlowLayout Masonry Grid',
        description: 'A UICVFlowLayout subclass fully implemented to illustrate usage. This will later be available as a CocoaPod.',
        url: 'https://github.com/Maxnelson997/PinterestLayout-UICollectionViewFlowLayout',
        logos: ['swift.png']
    },
    {
        _id: 3,
        title: 'Redux Pinterest Grid',
        description: 'This app was built to show my understanding of all README.md listed technologies. (click see repo.)',
        url: 'https://github.com/Maxnelson997/ReduxGridPinterestLayout',
        logos: ['react.png', 'redux.png']
    },
]

const Repo = ({title, description, url, logos}) => {
    return (
        <div className='repo'>
            <div className='repo__logos'>
                {
                    logos ? logos.map((logo, index) => {
                        return <img src={`/assets/technologies/${logo}`} key={index}/>
                    }) : ''
                }
            </div>
            <div className='repo__title'>{title}</div>
            <div className='repo__description'>{description}</div>
            <div className='repo__url'>
                <a target="_blank" href={url}>See Repo</a>
            </div>
        </div>
    )
}

const GithubRepos = ({children}) => {
    return (
        <div className='github-repos'>
            <div className='github-repos__title'>GitHub Repos.</div>
            <div className='github-repos__desc'>I learn by building projects with new technologies.</div>
            <div className='github-repos__repos'>
                {
                    repos.map(repo => {
                        return <Repo key={repo._id} {...repo} />
                    })
                }
            </div>
        </div>
    )
}

export default GithubRepos;
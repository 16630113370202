import React, { Component } from 'react'

class AboutItem extends Component {
   render() {
       const { className, photos } = this.props
       return (

      

         
                         photos ? 
                         this.renderLargeAboutItem()
                          :
                          this.renderBasicAboutItem()
      
      
       )
   }

   handlePhotoClick = (index) => {
    //  let largePhoto = document.getElementsByClassName(`about-item-large__photo-${index}`)[0]
    //  console.log(`about-item-large__photo-${index}`)
    //  console.log(index)
    //  largePhoto.classList.add('large-photo')
   }

   renderLargeAboutItem = () => {
        const { title, description, photos } = this.props;
        return (
            <div className='about-item about-item-large'>
                <h1 className='about-item-large__title'>{title}</h1>
                <p className='about-item-large__description'>{description}</p>
                <ul className='about-item-large__photos'>
                    {
                        photos.map((photo, index) => {
                            let selc = Math.random()
                            return (
                            <img
                                className={`about-item-large__photo-${selc}`}
                                onClick={() => this.handlePhotoClick(selc)}
                                src={photo}
                                key={selc}
                            />
                            )
                        })
                    }
                </ul>
            </div>

        ) 
   }

   renderBasicAboutItem = () => {
        const { title, description } = this.props; 
       return (

        <div className='about-item about-item-basic'>
            <h1 className='about-item-basic__title'>{title}</h1>
            <p className='about-item-basic__description'>{description}</p>
        </div>

       ) 
   }
}

export default AboutItem;
import React, { Component } from 'react'
// import { connect } from 'react-redux';
// import { fetchUdemyCourses } from '../../actions';

class Instructor extends Component {

   // componentDidMount() {
   //    this.props.fetchUdemyCourses()
   // }

   renderCourses = () => {
      const items = []
      console.log(this.props.udemyCourses.results)
      this.props.udemyCourses.results ? 
       this.props.udemyCourses.results.map((course, index) => {
         items.push (
            <div key={index} className='udemy-course'>
               <div className='udemy-course__title'>{course.title}</div>
               <a target="_blank" href={`https://www.udemy.com${course.url}?couponCode=MAXCODES`} className='udemy-course__button'>View Details</a>
            </div>
         )
      })
      : ''
      return items
   }

   render() {
       const { className } = this.props
       return (
            <div className={`${className} instructor`}>
               <div className='instructor__title'>I teach others what I know.</div>
               <div className='instructor__description'>I know how to code due to the generosity of other instructors. So I do the same for other developers. Click the link to see a full list of the corses I teach on Udemy.</div>
               <a className='instructor__button' target="_blank" href="https://www.udemy.com/user/maxwellnelson">
                  View The Courses I Teach.
               </a>
               {/* <iframe className='instructor__udemy' src="https://www.udemy.com/user/maxwellnelson"></iframe> */}
               {/* <div className='instructor__courses udemy-courses'>
                  {this.renderCourses()}
               </div> */}

            </div>
       )
   }
}

// function mapStateToProps(state) {
//    const { udemyCourses } = state.udemy;
//    return {
//       udemyCourses
//    }
// }

// Instructor = connect(mapStateToProps, { fetchUdemyCourses })(Instructor);
export default Instructor;
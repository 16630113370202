import { SET_PROJECT_CATEGORY } from '../actions/types';

const INITIAL_STATE = {
    selectedCategory: 'all',
    projectsCategories: [
        {
            _id: 0,
            title: 'All Projects',
            category: 'all',
            selected: true
        },
        {
            _id: 1,
            title: 'Web Apps',
            category: 'web',
            selected: false
        },
        {
            _id: 2,
            title: 'iOS Apps',
            category: 'ios',
            selected: false
        },
        {
            _id: 3,
            title: 'GitHub Repos',
            category: 'repo',
            selected: false
        }
    ],
    projects: [

       {
          _id: 2,
          type: 'ios',
          title: "PocketPapers",
          description: "wallpapers, in your pocket.",
          iconUrl: "/assets/iosapps/pp/logo.jpg",
          tags: [
            "Swift",
            "Node",
            "Express",
            "Mongo",
            "Firebase",
            "Core Data"
        ],
          images: [
             "/assets/iosapps/pp/1.jpg",
             "/assets/iosapps/pp/2.jpg",
             "/assets/iosapps/pp/3.jpg",
             "/assets/iosapps/pp/5.jpg",
             "/assets/iosapps/pp/4.jpg"
          ],
          url: "https://apps.apple.com/us/app/pocketpapers/id1320376517?ls=1"
       },
       {
        _id: 12,
        type: 'web',
        title: "maxnelson.io",
        description: "My Resume & Portfolio. You are on this site right now. Noteable features include project filtering, an adaptable timeline component, typewriter component, and more.",
        iconUrl: "/assets/technologies/chrome.png",
        tags: [
            "JavaScript",
            "React",
            'React Hooks',
            "Webpack",
            "SCSS",
            "CSS Grid & FlexBox",
            "React Context"
        ],
        images: [
         "/assets/maxnelsonsiteshot001.png",
         "/assets/maxnelsonsiteshot000.png",
         "/assets/maxnelsonsiteshot002.png"
        ],
        url: "https://www.maxnelson.io",
    },
   {
        _id: 1,
        type: 'web',
        title: "apply.bottega.tech",
        description: "Enrollment Web Application with various service integrations such as Calendly, Tuition Teller for payment processing, etc..",
        iconUrl: "/assets/technologies/chrome.png",
        tags: [
            "Mongo",
            "Express",
            "Node",
            "React"
        ],
        images: [
         "/assets/applybottegatechsiteshot000.png",,
         "/assets/applybottegatechsiteshot001.png",
        ],
        url: "https://apply.bottega.tech",
    },
    {
        _id: 0,
        type: 'web',
        title: "maxcodes.io",
        description: "Online learning. For iOS Developers.",
        iconUrl: "/assets/technologies/chrome.png",
        tags: [
            "JavaScript",
            "React",
            "Mongo",
            "Express",
            "Node"
        ],
        images: [
            "/assets/maxcodessiteshot000.png",
            "/assets/maxcodessiteshot001.png",
            "/assets/maxcodessiteshot002.png",
        ],
        url: "https://www.maxcodes.io",
    },
       {
          _id: 3,
          type: 'ios',
          title: "GPYay!",
          description: "College GPA Calculator",
          iconUrl: "/assets/iosapps/gpyay/logo.jpg",
          tags: [
            "Swift",
            "Firebase",
            "Core Data"
          ],
          images: [
             "/assets/iosapps/gpyay/1.jpg",
             "/assets/iosapps/gpyay/2.jpg",
             "/assets/iosapps/gpyay/3.jpg",
             "/assets/iosapps/gpyay/4.jpg",
          ],
          url: "https://apps.apple.com/us/app/gpyay/id1112838673?ls=1"
       },
       {
          _id: 4,
          type: 'ios',
          title: "Decipher",
          description: "QR Code Scanner",
          iconUrl: "/assets/iosapps/decipher/logo.jpg",
          tags: [
            "Swift",
            "Firebase",
            "AVFoundation"
        ],
    
          images: [
             "/assets/iosapps/decipher/1.png",
             "/assets/iosapps/decipher/2.png",
             "/assets/iosapps/decipher/3.png",
             "/assets/iosapps/decipher/4.png",
             "/assets/iosapps/decipher/5.png",
          ],
          url: "https://apps.apple.com/us/app/decipher-qr-code-scanner/id1299774528?ls=1"
       },
       {
          _id: 5,
          type: 'ios',
          title: "Color On Me",
          description: "Photo Editing & Filters",
          iconUrl: "/assets/iosapps/com/logo.jpg",
          tags: [
            "Swift",
            "Quartz Core"
        ],
          images: [
             "/assets/iosapps/com/1.png",
             "/assets/iosapps/com/2.png",
             "/assets/iosapps/com/3.png",
             "/assets/iosapps/com/4.png",
             "/assets/iosapps/com/5.png",
          ],
          url: "https://apps.apple.com/us/app/color-on-me/id1255071108?ls=1"
       },
       {
          _id: 6,
          type: 'ios',
          title: "College Course Average",
          description: "Calculate weighted grade groups.",
          iconUrl: "/assets/iosapps/cga/logo.jpg",
          tags: [
            "Swift",
            "Core Data"
        ],
          images: [
             "/assets/iosapps/cga/1.jpg",
             "/assets/iosapps/cga/2.jpg",
             "/assets/iosapps/cga/3.jpg",
             "/assets/iosapps/cga/4.jpg",
          ],
          url: "https://apps.apple.com/us/app/college-course-average/id1178814697?ls=1"
       },
       {
          _id: 7,
          type: 'repo',
          title: 'Koa-Typescript',
          description: 'A koa typescript app. Redis for data, supported by Docker.',
          iconUrl: "/assets/githubwhite.png",
          tags: ['Docker', 'Node', 'Koa'],
          images: [
          ],
          url: 'https://github.com/Maxnelson997/koa-typescript'
       },
       {
        _id: 8,
        type: 'repo',
        title: 'WebpackBabelReact-InDepth',
        description: 'A fully fleshed out webpack setup for react applications with SCSS support.',
        iconUrl: "/assets/githubwhite.png",
        tags: ['Webpack', 'JS', 'React'],
        images: [
        ],
        url: 'https://github.com/Maxnelson997/WebpackBabelReact-InDepth'
        },
        {
            _id: 9,
            type: 'repo',
            title: 'UICollectionViewFlowLayout Masonry Grid',
            description: 'A UICVFlowLayout subclass fully implemented to illustrate usage. This will later be available as a CocoaPod.',
            iconUrl: "/assets/githubwhite.png",
            tags: ['Swift'],
            images: [
            ],
            url: 'https://github.com/Maxnelson997/PinterestLayout-UICollectionViewFlowLayout',
        },
        {
            _id: 10,
            type: 'repo',
            title: 'Redux Pinterest Grid',
            description: 'This app was built to show my understanding of all README.md listed technologies. (click see repo.)',
            iconUrl: "/assets/githubwhite.png",
            tags: ['React', 'Redux'],
            images: [
            ],
            url: 'https://github.com/Maxnelson997/ReduxGridPinterestLayout',
        },
        {
            _id: 11,
            type: 'repo',
            title: 'JS Media Query React Hook Setup',
            description: `A solid way to watch for breakpoints in your JS React Hooks applications when absolutely necessary.
            Sometimes we can't do everything for our layout with CSS and we need to make changes directly in our code when the screen is a certain size or maybe when a user resizes our application. This repo contains the keyzzz to solve this pretty nicely. It also contains React Hooks usage.`,
            iconUrl: "/assets/githubwhite.png",
            tags: ['React', 'Redux', 'Window.matchMedia()', 'MediaQueryList', 'React Hooks'],
            images: [
            ],
            url: 'https://github.com/Maxnelson997/ReactHooksJSMediaQueries',
        }
    ]
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case SET_PROJECT_CATEGORY:
            let newProjectsCategories = [...state.projectsCategories].map(cat => {
                cat.selected = action.payload === cat.category ? true : false
                return cat
            })
            return {
                ...state,
                selectedCategory: action.payload,
                projectsCategories: newProjectsCategories
            }
        default: return INITIAL_STATE
    }
}
import { combineReducers } from 'redux';
import projectsReducer from './projectsReducer';
import scrollReducer from './scrollReducer';
import udemyReducer from './udemyReducer';

const rootReducer = combineReducers({
  udemy: udemyReducer,
  projects: projectsReducer,
  scrollValues: scrollReducer
});

export default rootReducer;
import React from 'react'

let mentoringBlockData = [
    {
        url: 'https://medium.com/@max.codes',
        _id: 0,
        icon: "assets/app-store.png",
        title: "Medium Articles",
        description: "Written guides on how to use specific classes, protocols, and methods within the Swift programming language. For many of these guides I have provided an accompanying YouTube video."
    },
    {
        url: 'https://www.udemy.com/user/maxwellnelson/',
        _id: 2,
        icon: "assets/video.png",
        title: "Udemy Courses",
        description: "I've organized curriculum for specific technologies within Swift & Xcode and recorded myself explaining step by step how to build apps with these technologies."
    },
    {
        url: 'http://youtube.com/maxcodes',
        _id: 1,
        icon: "assets/safari.png",
        title: "YouTube Videos",
        description: "Concept specific video guides in the Swift language. These videos help iOS developers learn new techniques, Swift provided classes and protocols, and new approaches to building mobile features."
    }
]

const MentoringBlock = ({_id, icon, title, description, url}) => {
    return (
        <div className='mentoring-block'>
            <img className='mentoring-block__icon' src={icon}/>
            <div className='mentoring-block__title'>{title}</div>
            <div className='mentoring-block__description'>{description}</div>
            {
                // _id == 2 ? 
                // <a className='mentoring-block__button' 
                //     target='_blank'
                //     href={url}
                // >
                //     More Info
                // </a>
                // :
                <a className='mentoring-block__button'
                    // onClick={() => history.push(url)}
                    href={url}
                    target="_blank"
                >
                    Go To Resource
                </a>
            }
        </div>
    )
}

let Mentoring = ({className}) => {
       return (
            <div className={`${className} mentoring`}>
                <div className='mentoring__info'>
                    <div className='mentoring__info__title'>Mentoring</div>
                    <div className='mentoring__info__description'>In my freetime I create videos, articles, and concept specific courses to help others learn how to code.</div>
                </div>
                <div className={`${className} mentoring__blocks`}>
                {
                    mentoringBlockData.map((mentoringBlock, index) => {
                        return <MentoringBlock key={index} {...mentoringBlock}/>
                    })
                }
                </div>
                <img className='mentoring__img' src='/assets/streetphotography/setup.jpg'/>
            </div>
       )
}

export default Mentoring;
import React, { Component } from 'react'
import MCButton from '../clusters/MCButton';

class WebProject extends Component {

    genButton = () => {
        if(this.props.available) {
            return <MCButton onClick={() => this.handleViewProjects(url)} className='web-project__card__button' text='Go To Site'/>
        } else { 
            return <MCButton isDisabled={true} onClick={() => this.handleViewProjects(url)} className='web-project__card__button' text='Source Code Coming Soon.'/>
        }
    }
   render() {
       const { className, subtitle, title, url, images } = this.props
       return (
            <div className={`${className} web-project`}>
                <div className='web-project__card'>
                    <div className='web-project__card__line'/>
                    <div className='web-project__card__subtitle'>{subtitle}</div>
                    <div className='web-project__card__title'>{title}</div>
                    {this.genButton()}
                </div>
                <div className='web-project__images'>
                    {images.map((image, index) => {
                        return <img src={image} key={index}/>
                    })}
                </div>
            </div>
       )
   }
   handleViewProjects = (url) => {
    window.open(url);
  }
}

export default WebProject;
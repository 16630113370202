import React, { Component } from 'react'

class MCButton extends Component {
   render() {
       const { className, text, onClick, isDisabled} = this.props
       return (
            <a disabled={isDisabled} onClick={onClick} className={`${className} mcbutton ${isDisabled ? 'mcbutton-disabled' : ''}`}>
               {text}
            </a>
       )
   }
}

export default MCButton;
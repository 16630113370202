import React from 'react';
import Timeline from './timeline';

const TimelineSection = ({className}) => {
    return (
        <div className={`${className} timeline-section`}>
            <div className='timeline-section__info'>
                <div className='timeline-section__info__title'>Timeline</div>
                <div className='timeline-section__info__description'>A most recent timeline of my career.</div>
            </div>
            <Timeline className='timeline-section__timeline'/>
        </div>
    )
}

export default TimelineSection;
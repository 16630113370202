import React, { Component } from 'react'
import AboutItem from './aboutItem';
import ProjectsHeader from '../projects/projectsHeader';

// {
//     _id: 0,
//     title: '',
//     description: '',
//     photos: [
//         '/assets/books/.png'
//     ]
// },

let data = [
    {
        _id: 5,
        title: 'Interior Design & Architecture',
        description: 'The space we iive in affects our feelings and behaviors. While reacting on emotions is not ideal, I believe there should be a fine balance between effort and surrender. I\'ve found when I am in a clean room with a well thought-out layout, I am able to focus on the present activty with much more precision. Work is more often than not, this activity.',
        photos: [
            '/assets/interiordesign/room0.jpg',
            '/assets/interiordesign/room.jpg',
            '/assets/interiordesign/room1.jpg',
            '/assets/interiordesign/room2.jpg',
            '/assets/interiordesign/room3.jpg',
            '/assets/interiordesign/room4.jpg',
            '/assets/interiordesign/room5.jpg',
            '/assets/interiordesign/room6.jpg',
            '/assets/interiordesign/room7.jpg',
        ]
    },

    {
        _id: 1,
        title: 'Street Photography',
        description: 'I love the feeling I get after editing a clean set of photos. It’s rugged, but it’s also a clean feeling; much like the feeling I get when I walk into a room with well-thought interior design. Here’s a collection of photos I’ve taken.',
        photos: [
            '/assets/streetphotography/frs.png',
            '/assets/streetphotography/timmy.png',
            '/assets/streetphotography/josh.png',
            '/assets/streetphotography/dvlpr.png',
            '/assets/streetphotography/biker.png',
            '/assets/streetphotography/josh2.png',
            '/assets/streetphotography/mic.png'
        ]
    },
    // {
    //     _id: 3,
    //     title: 'Music Production',
    //     description: 'Having a mix of analytical and creative thinking, music production gives me the ability to let my creative side flow freely. I strongly believe this is an important interest of mine due to the unlimited creative potential... It\'s what I like to call \"A productive escape\".'
    // },
    {
        _id: 4,
        title: 'Performance Cars',
        description: 'Driving is one of my favorite things to do when given the right road. I strongly believe there is a coorelation between clean code, elegant yet precise driving, and many aspects of design.'
    },
    {
        _id: 5,
        title: 'Snowboarding',
        description: 'There\'s nothing quite like sitting on a snowy hill alone first thing in the morning. This is with the exception of going down a snowy hill with your favorite people or person at questionable speeds.',
    },
    {
        _id: 2,
        title: 'Running',
        description: 'Running is difficult. So I make sure to run. It also feels great. It\'s complicated.',
    },
    {
        _id: 0,
        title: 'Books and Self-Awareness',
        description: 'I read books to give myself knowledge that will help me better understand myself. By increasing my self-awareness I am better able to perform in every other area of my life. Here is a list of my favorite books.',
        photos: [
            '/assets/books/canthurtme.png',
            '/assets/books/extremeownership.png',
            '/assets/books/jpete.png',
            '/assets/books/wayofthewolf.png',
            '/assets/books/jpete12.png',
            '/assets/books/thealchemist.png',
        ]
    },
 
]

class About extends Component {
   render() {
       const { className } = this.props
       return (
            <div className={`${className} about-container`}>
                <ProjectsHeader className='about-container__header' title='Who Is Maxwell?' subtitle="I do the codes. I do other things too though."/>
                <div className='about'>
                {
                    data.map((item, index) => {
                        return <AboutItem key={index} {...item}/>
                    })
                }
                </div>
            </div>
       )
   }
}

export default About;
import { SET_SCROLL } from '../actions/types';

const INITIAL_STATE = {
    scrollY: "",
    scrollX: "",
    scrollDirection: "",
}

export default function(state = INITIAL_STATE, action) {
    let pay = action.payload;
    switch(action.type) {
        case SET_SCROLL:
            return {
                scrollY: pay.scrollY,
                scrollX: pay.scrollX,
                scrollDirection: pay.scrollDirection,
            }
        default: return INITIAL_STATE
    }
}
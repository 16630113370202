import React, { Component } from 'react'
import SocialProfiles from '../clusters/socialProfiles';

class ProjectsHeader extends Component {
   render() {
       const { className, title, subtitle } = this.props
       return (
            <div className={`${className} projects-header`}>
                <div className='projects-header__title'>{title}</div>
                <div className='projects-header__subtitle'>{subtitle}</div>
                <SocialProfiles className='projects-header__social-profiles'/>
                <div className='projects-header__connect-with-me'>connect with me anywhere.</div>
            </div>
       )
   }
}

export default ProjectsHeader;
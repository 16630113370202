import React, { useEffect } from 'react'
import MCButton from '../clusters/MCButton';

const Project = ({className, type, title, description, images, tags, url, iconUrl}) => {
    const buttonType = (type) => {
        switch (type) {
            case "repo":
                return "See The Code"
                break;
            case "ios":
                return "View on App Store"
                break;
            case "web":
                return "View Site"
                break;
            default:
                return "check type in data."
                break;
        }
    }
    return (
        <div className={`${className} project ${images.length < 1 ? 'project--no-images' : ''}`}>
            {
                images.length > 0 ? 
                    <div className='project__images'>
                        {images.map((image, index) => {
                            return <img src={image} key={index}/>
                        })}
                    </div>
                : ''
            }
            <div className='project__card'>
                <div className='project__card__title'>{title}</div>
                <div className='project__card__description'>{description}</div>
                <div className='project__card__tags'>
                    {
                        tags.map((tag, key) => {
                            return <div key={key} className='project__card__tags__tag'>{tag}</div>
                        })
                    }
                </div>
                <img className='project__card__icon' src={iconUrl}/>    
                <MCButton onClick={() => window.open(url)} className='project__card__button' text={buttonType(type)}/>
            
            </div>
        </div>
    )
}

const FadeCarousel = ({className, list}) => {

    // useEffect(() => {
    //     setTimeout(() => {
    //         let current = 0
    //         let fadeCurrent = 0
    //         list != undefined ? 
    //         list.reduce((acc, next) => {
    //             current += 1;
    //             fadeCurrent += 1;
    //             // let currentElement = document.getElementsByClassName(`fade-carousel__name:${item}`)[0];
    //             // currentElement.style.opacity = 0;

    //             if(next != list[list.length]) {
    //                 let nextElement = document.getElementsByClassName(`fade-carousel__name:${next}`)[0];
    //                 setTimeout(() => {
    //                     console.log(nextElement)
    //                     nextElement.style.opacity = 1;

    //                     setTimeout(() => {
    //                         nextElement.style.opacity = 0;
    //                     }, 1000);
    //                 }, 1000 * current);
    //             }
    //             acc = acc + next;
    //             return acc
    //         }, list[0])
    //         : console.log('list is undefined right now.')
    //     }, 1000)
    //     return () => { console.log('unmounting.')}
    // }, [list])


    return (
        <div className={`${className} fade-carousel`}>
            {
                list.map((item, key) => {
                    return <div key={key} className={`fade-carousel__item fade-carousel__name:${item}`}>{item}</div>
                })
            }
        </div>
    )
}

export default Project;
import React, { Component } from 'react'

class Resume extends Component {
   render() {
       const { className } = this.props
       return (
            <div className={`${className} resume`}>
               <iframe height="400px" width="400px" src="../../../static/assets/maxnelsonios.pdf"/>
            </div>
       )
   }
}

export default Resume;
import React, { useEffect, useState } from 'react';
import {useSpring, animated} from 'react-spring'
import useBreakpoint from '../../breakpoint/useBreakpoint';

const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November", 
    "December"
]; 

// const x = window.matchMedia("(max-width: 700px)");

const TimelineEvent = (DATA) => {

    const breakpoints = useBreakpoint();

    const [ mobile, setMobile ] = useState(false)

    useEffect(() => {
        setMobile(breakpoints["mobile"])
    })

    useEffect(() => {
        let eventElement = document.getElementsByClassName(`timeline-event__${DATA.year}-${DATA._id}`)[0];

        let month = eventElement.childNodes[2];
        // month.innerHTML = monthNames[DATA.month - 1];
        let line = eventElement.childNodes[0];
        let description = eventElement.childNodes[1];
        let logo = eventElement.childNodes[3];

        if(!DATA.logoURL) { 
            eventElement.style.gridTemplateRows = `[line-s month-s event-s] 1fr [event-e month-e line-e]`;
            line.style.alignSelf = 'start';
            month.style.alignSelf = 'start';
            description.style.alignSelf = 'start';
            month.style.marginTop = '-13px';
            // line.style.marginTop = '13px';
            description.style.marginTop= '-6px';
        }

        // if(DATA.startEvent) {

            // eventElement.style.gridTemplateColumns = `[event-s logo-s] 1fr [logo-e event-e month-s line-s] 110px [line-e]`;

            // description.style.textAlign = `right`;

            // line.style.marginLeft = `20px`;
            // line.style.marginRight = `0px`;

            // month.style.gridColumnStart = `month-s`;
            // month.style.gridColumnEnd = `unset`;
            // month.style.textAlign = `left`;
            // month.style.marginLeft = `20px`;
            // month.style.marginRight = `0px`;

            // logo.style.justifySelf = `right`;
        // }
    });

    const animationProps = useSpring({opacity: 1, from: {opacity: 0}})

    return (
        <animated.div style={animationProps} data-start-event={DATA.startEvent} className={`timeline-event timeline-event__${DATA.startEvent ? 'start-event' : 'end-event'} timeline-event__${DATA.year} timeline-event__${DATA.year}-${DATA._id}`}>
            <div className={`timeline-event__line timeline-event__${DATA.startEvent ? 'start-event__line' : 'end-event__line'}`}/>
            <div className={`timeline-event__event timeline-event__${DATA.startEvent ? 'start-event__event' : 'end-event__event'}`}>{DATA.event}</div>
            {
                breakpoints["mobile"] ? 
                <div className={`timeline-event__month timeline-event__${DATA.startEvent ? 'start-event__month' : 'end-event__month'}`}>{DATA.month}</div>
                :
                <div className={`timeline-event__month timeline-event__${DATA.startEvent ? 'start-event__month' : 'end-event__month'}`}>{monthNames[DATA.month - 1]}</div>
            }
            <img className={`timeline-event__logo timeline-event__${DATA.startEvent ? 'start-event__logo' : 'end-event__logo'}`} src={DATA.logoURL != undefined ? DATA.logoURL : ''}/>
        </animated.div>
    );
}

export default TimelineEvent;
import React, { Component } from 'react'

class Spotify extends Component {

    componentDidMount() {
      
    }
   render() {
       const { className } = this.props
       return (
            <div className={`${className} spotify`}>
                <div className='spotify__title'>What I listen to while coding.</div>
                <div className='spotify__description'>This is the music that puts me in the code-mode so I can build robust web applications fast and efficiently.</div>
                <div className='spotify__players'>
                    <iframe src="https://open.spotify.com/embed/playlist/5dvThIHfRiqzxtA1FEvWmr" width="100%" height="100%" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                    <iframe src="https://open.spotify.com/embed/playlist/4wBSleM33mvBqm8GexmLKg" width="100%" height="100%" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                    <iframe src="https://open.spotify.com/embed/playlist/3pycYnXMrSJuZQO7WfOuJI" width="100%" height="100%" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </div>
            </div>
       )
   }
}

export default Spotify;
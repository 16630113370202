import React, { Component } from 'react';
import HomeHeader from './homeHeader';
import Blocks from './blocks';
import Mentoring from './mentoring';
import TimelineSection from './timeline/timeline-section';
import Phones from './phones';
import Technologies from './technologies';
import Instructor from './instructor';
import Spotify from '../spotify/spotify';
import GithubRepos from '../home/githubRepos';

import particles from './particles-conf.json'

export default class Home extends Component {

  componentDidMount() {
    particlesJS('particles-js', particles)
  }

  render() {
    return (
      <div className='home layout__content'>
        <HomeHeader className='home__home-header' history={this.props.history}/>
        {/* <GithubRepos className='home__repos'/> */}
        <Blocks className='home__blocks'/>
        <TimelineSection className='home__timeline'/>
        <Mentoring className='home__mentoring'/>
        {/* <div className='home__separator'></div> */}
        {/* <Technologies className='home__tech'/> */}
        {/* <div className='home__separator'></div> */}
        {/* <Spotify className='home__spotify'/> */}
        {/* <div className='home__separator'></div> */}
        {/* <Instructor className='home__instructor'/> */}

        {/* <div className='home__separator'></div> */}
        {/* <Phones className='home__phones'/>  */}
      </div>
    );
  }
}
import React from 'react';
import TimelineYear from './timelineYear';

const TL_DATA = [
    {
        _id: 0,
        year: 2018,
        events: [
            {
                _id: 0,
                event: 'Accepted offer to work as a Software Engineer and Instructor at Bottega. Core work included building their enrollment app streamline student enrollment and increase conversions. Recorded myself bulding several front-end web apps and explaining each step to be used for the React curriculum.',
                logoURL: '/assets/events/bottegalogo.png',
                month: 1,
                startEvent: true
            }
        ]
    },
    {
        _id: 1,
        year: 2019,
        events: [
            {
                _id: 0,
                event: 'Left Bottega to explore new ideas and find new growth.',
                logoURL: '/assets/events/bottegalogo.png',
                month: 2,
                startEvent: false
            },
            {
                _id: 1,
                event: 'Spent the time between Bottega and Prog making Udemy courses on iOS development.',
                logoURL: '/assets/events/udemy.png',
                month: 3,
                startEvent: false
            },
            {
                _id: 2,
                event: 'Accepted offer to work as a Software Engineer at Progressive Leasing. Core work included building animation heavy Angular components for the internal design system. Followed a mix of agile methedologies to organize and approach work.',
                logoURL: '/assets/events/proglogo.jpg',
                month: 9,
                startEvent: true
            }
        ]
    },
    {
        _id: 2,
        year: 2020,
        events: [
            {
                _id: 0,
                event: 'The Rona strikes. Laid off from Progressive Leasing due to COVID-19.',
                logoURL: '/assets/events/proglogo.jpg',
                month: 4,
                startEvent: false
            },
            {
                _id: 1,
                event: 'Spent the summer consuming knowledge like a hungry hippo. Fell in love with front-end architecture. Educated myself on many details of a software developer\'s career, for example, organizational details like the role of culture and process and the effects of making changes to each.',
                logoURL: '/assets/technologies/webpack.png',
                month: 5,
                startEvent: true
            },
            {
                _id: 2,
                event: 'Officially started looking for work.',
                logoURL: '/assets/me.ico',
                month: 10,
                startEvent: false
            },
            {
                _id: 3,
                event: 'Accepted offer to work as a Software Engineer at Tulgy.',
                logoURL: '/assets/events/tulgycolor.png',
                month: 12,
                startEvent: true
            },
            // {
            //     _id: 2,
            //     event: 'Accepted offer to work as a Software Engineer at Tulgy development agency. Core responsibilites include overseeing front-end architecture and working with clients.',
            //     logoURL: '/assets/events/tulgy.png',
            //     month: 12,
            //     startEvent: false
            // }
        ]
    //         {
    //             _id: 1,
    //             event: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    //             month: 6,
    //             startEvent: true
    //         },
    //         {
    //             _id: 2,
    //             event: 'scing elit, sed do eiusmod tempor incididunt ut labore et dolore magna alim, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    //             month: 8,
    //             startEvent: true
    //         },
    //         {
    //             _id: 3,
    //             event: 'lpsum dolor sit amet, consectetur adipiscing elit, sed d psum dolor sit amet, consectetur adipiscing elit, sed d',
    //             month: 10,
    //             startEvent: false
    //         },
    //         {
    //             _id: 4,
    //             event: 'lpsum dolor sit amet, consectetur adipispsum dolor sit amet, consectetur adipiscing elit, sed d psum dolor sit amet, consectetur adipiscing elit, sed d',
    //             month: 11,
    //             startEvent: true
    //         },
    //         {
    //             _id: 5,
    //             event: 'lpsum sit amet, consectetur adipispsum d sit amet, consectetur adipispsum dolor sit a sit amet, consectetur adipispsum dolor sit a sit amet, consectetur adipispsum dolor sit aolor sit a sit amet, consectetur adipispsum dolor sit a sit amet, consectetur adipispsum dolor sit a sit amet, consectetur adipispsum dolor sit amet, consectetur adipiscing elit, sed d psum dolor sit amet, consectetur adipiscing elit, sed d',
    //             month: 12,
    //             startEvent: false
    //         },
    //     ]
    },
    {
        _id: 3,
        year: 2021,
        events: [
            {
                _id: 0,
                event: 'Left Tulgy to find a better match. Tulgy was\'nt a fit for my career goals.',
                logoURL: '/assets/events/tulgycolor.png',
                month: 8,
                startEvent: false
            },
            {
                _id: 1,
                event: 'Officially started looking for a Front-End role. My biggest priority is to stay at my next position for at least 2+ years. This will strengthen my resume and provide meaningful contribution to an organization\'s progress.',
                logoURL: '/assets/me.ico',
                month: 8,
                startEvent: false
            }
            // {
            //     _id: 2,
            //     event: 'Accepted offer to work as a Software Engineer at Tulgy development agency. Core responsibilites include overseeing front-end architecture and working with clients.',
            //     logoURL: '/assets/events/tulgy.png',
            //     month: 12,
            //     startEvent: false
            // }
        ]
    }
]

const Timeline = ({className}) => {
    return (
        <div className={`${className} timeline`}>
            <div className='timeline__years'>
                {
                    TL_DATA.map(data => {
                        console.log({...data})
                        return <TimelineYear key={data._id} {...data} />
                    })
                }
            </div>
        </div>
    )
}

export default Timeline;